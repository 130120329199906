// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-single-course-js": () => import("/opt/build/repo/src/templates/single-course.js" /* webpackChunkName: "component---src-templates-single-course-js" */),
  "component---src-templates-single-blog-js": () => import("/opt/build/repo/src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-courses-js": () => import("/opt/build/repo/src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

